import { template as template_b8b12d1ff62e4bbabc5a46be66ac5b99 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b8b12d1ff62e4bbabc5a46be66ac5b99(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
