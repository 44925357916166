import { template as template_bd4c42a1b886471cb42c44c712b1cf64 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_bd4c42a1b886471cb42c44c712b1cf64(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
